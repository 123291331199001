import React, { Component } from 'react';
import { getDesignCodePreference } from '../../utility/js/designCodePreference';

export default class GetStartedIndex extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    const initialPreference = getDesignCodePreference();
    window.___navigate('/get-started/' + initialPreference);
  }

  render() {
    return <div />;
  }
}
